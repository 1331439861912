import React from "react";
import { Link } from "gatsby";
import './index.scss'
import axios from "axios";
import { BASE_URL } from "../../../config";
import { useDispatch } from "react-redux";
import { setLoginAction } from "../../redux/auth/authSlice";

import { useTranslation } from 'react-i18next';
import {graphql} from 'gatsby';

const PaymentConfirmationPage = ({location}) => {
  
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search)
  const transactionId = searchParams.get("id");
  
  const [loading, setLoading] = React.useState(true)
  const [statusOrder, setStatusOrder] = React.useState(null)
  const [orderData, setOrderData] = React.useState(null)


  React.useEffect(() => {
    const savedToken = localStorage.getItem('authToken');
    if (savedToken) {
        dispatch(setLoginAction(JSON.parse(savedToken)));
    }
  }, [dispatch]);

  React.useEffect(()=>{
    getStatusOrder();
  },[])

  React.useEffect(()=>{

    
    if(statusOrder && statusOrder == "APPROVED"){

      if (typeof window !== 'undefined') {
        
        //Send event to GTM
        window.dataLayer.push({
          event: "purchase",
          ecommerce: {
            transaction_id: orderData.transactionId,
            value: parseFloat(orderData.amount),
            currency: "USD",
            items: orderData.orderItems.map((data)=>{
              return{
                item_id: data.id,
                item_name: data.course.name,
                price: data.course.priceNow,
                quantity: 1
              }
            })
          }
          
        });
        
      }

    }
  },[orderData])

  const getStatusOrder = async () => {
    try {
      const result = await axios.get(BASE_URL+"/orders?transactionId="+transactionId)
      setOrderData(result.data[0])
      setStatusOrder(result.data[0].paymentStatus)
      setLoading(false);
    } catch (error) {
      
    }
  }
  return (
    <div className="ticket-bg">
  <div className="ticket">
    {!loading && statusOrder === 'PAID' && (
      <div className="text-center py-4">
        <div className="text-success display-1">&#10004;</div>
        <h1 className="display-4">{t('PaymentApprove')}</h1>
        <p className="lead">¡{t('YourPaymentHasBeenConfirmedSuccessfully')}!</p>
        <Link to="/student-profile/" className="edu-btn">{t('MyProfile')}</Link>
      </div>
    )}

    {!loading && statusOrder === 'PENDING' && (
      <div className="text-center py-4">
        <div className="text-warning display-1">&#9888;</div>
        <h1 className="display-4">{t('PaymentPending')}</h1>
        <p className="lead">{t('YourPaymentIsPending')}</p>
        <Link to="/" className="edu-btn">{t('GoToHome')}</Link>
      </div>
    )}

    {!loading && statusOrder !== 'PAID' && statusOrder !== 'PENDING' && (
      <div className="text-center py-4">
        <div className="text-danger display-1">&#10060;</div>
        <h1 className="display-4">{t('PaymentRejected')}</h1>
        <p className="lead">{t('YourPaymentHasBeenRejected')}</p>
        <Link to="/" className="edu-btn">{t('GoToHome')}</Link>
      </div>
    )}

    {loading && !statusOrder && (
      <div className="text-center py-4">
        <p className="lead">{t('WeAreLoadingPaymentInformation')}</p>
      </div>
    )}
  </div>
</div>
  );
};

export default PaymentConfirmationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
